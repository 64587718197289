.whatsappIconShow {
  //   background: transparent;
  display: flex;
  flex-direction: column;
  z-index: 200;
  position: fixed;
  bottom: 150px;
  right: 30px;
  .CancellLogo {
    margin-left: 100%;
    cursor: pointer;
  }
  img {
    width: 60px;
    border-radius: 100%;
  }
}
.SHow {
  opacity: 1;
  transition: 0.3s;
}
.Hide {
  opacity: 0;
  transition: 0.3s;
  bottom: 200px;
}
@media (width: 768px) {
  .contact-pannel-btn {
    display: none;
  }
}
@media (max-width: 580px) {
  .whatsappIconShow {
    bottom: 10px;
    right: 100px;
  }
  .Hide {
    opacity: 0;
    transition: 0.3s;
    bottom: 100px;
  }
}
