.scrollToTop {
  background-color: var(--primaryLightColor);
  color: #fff;
  position: fixed;
  bottom: 60px;
  right: 30px;
  padding: 7px 12px;
  border-radius: 100%;
  font-size: 25px;
  cursor: pointer;
  z-index: 999;
}
@media (max-width: 580px) {
  .scrollToTop {
    bottom: 10px;
    right: 20px;
  }
}
