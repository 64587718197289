/* ===================== HERO ======================*/
.hero-area {
  padding: 0;
  position: relative;
}

.hero-area .hero-slide-item {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-size: cover;
}

.hero-area .owl-item {
  overflow: hidden;
}
.hero-area .hero-tex {
  outline: 1px solid gold;
}
.hero-area .hero-slide-item:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #02050b;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  z-index: -1;
}
.hero-area .OnlyWOrdBold {
  font-weight: 700;
}
.hero-social {
  position: absolute;
  left: 0;
  height: 100%;
  width: 100px;
  bottom: 0;
  z-index: 9;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 0;
}

.hero-social ul {
  padding-bottom: 100px;
  text-align: center;
  padding-top: 150px;
}

.hero-social ul li {
  display: block;
  list-style: none;
  margin-top: 25px;
}

.hero-social ul li a {
  font-size: 20px;
  color: #000;
}

.hero-social ul li a:hover {
  color: var(--primaryColor);
}

.hero-social p {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 3px;
  min-width: 150px;
}

.hero-slide-item .container {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.hero-slide-item .hero-text {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: right;
  padding-top: 60px;
  padding-left: 100px;
}

.hero-text h2 {
  color: #fff;
  font-size: var(--heroFont);
  letter-spacing: 6px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 70px;
}

.hero-action {
  margin-top: 30px;
}

.hero-text .btn-border {
  border-color: #fff;
  color: #fff;
  margin-left: 20px;
}
.hero-text .btn-border:hover {
  background: var(--primaryColor) none repeat scroll 0 0 !important;
  border-color: var(--primaryColor);
}
.hero-text .cta-btn {
  transform: 0.5s;
}
.hero-text .cta-btn:hover {
  transition: 0.5s;
  transform: scale(1.09);
  color: #fff;
}

.hero-slide-item .video-main {
  position: absolute;
  left: 25%;
  bottom: 20%;
}

.video-main {
  position: relative;
  display: inline-block;
}
.video {
  height: 65px;
  width: 65px;
  line-height: 65px;
  text-align: center;
  border-radius: 100%;
  background: transparent;
  color: var(--primaryColor);
  display: inline-block;
  background: #fff;
  z-index: 999;
  font-size: 20px;
}

@-webkit-keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.fa-play:before {
  content: "\f04b";
}

.waves {
  position: absolute;
  width: 165px;
  height: 165px;
  background: var(--primaryDarkColor);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 100%;
  right: -50px;
  bottom: -50px;
  z-index: -1;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.hero-slider {
  /* padding-left: 100px; */
}
.hero-slider .hero_arrows {
  padding-left: 150px;
  position: absolute;
  bottom: 30px;
  z-index: 11;
}

.hero-slider .hero_arrows button {
  width: 50px;
  height: 50px;
  border: 2px solid #fff !important;
  color: #fff !important;
  margin-right: 10px;
  /* border-radius: 50%; */
  font-size: 20px !important;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.hero-slider .hero_arrows button:hover {
  border: 2px solid var(--primaryColor) !important;
  color: var(--primaryColor) !important;
}
.Hero_mobile_Socials {
  display: none;
}
/* Responsive */
@media (min-width: 1600px) {
  .hero-slide-item .container {
    max-width: 80%;
  }
  .hero-text h2 {
    line-height: 120px;
  }
}
@media (max-width: 991px) {
  .hero-slide-item .video-main {
    left: 20%;
    bottom: 15%;
  }
}
@media (max-width: 991px) {
  .hero-social {
    width: 60px;
  }
  .hero-social ul li a {
    font-size: 14px;
  }
  .hero-social ul li {
    margin-top: 20px;
  }
  .hero-social p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .Hero_mobile_Socials {
    display: block;
  }
  .hero-social {
    display: none;
  }
  .hero-text br {
    display: none;
  }
  .hero-area .hero-social {
    display: none;
    /* left: -100px; */
    /* transition: 0.5s; */
  }
  .hero-area:hover .hero-social {
    left: 0;
    transition: 0.5s;
  }
  .hero-text h2 {
    font-size: var(--heroFont);
    letter-spacing: 3px;
  }
  .hero-action .cta-btn {
    display: inline-block;
    padding: 14px 17px !important;
    text-align: center;
    margin: 3px;
  }
  .hero-slide-item .video-main {
    /* display: none; */
  }
  .section-title {
    font-size: 38px;
    line-height: 44px;
  }
  .sub-title {
    letter-spacing: 3px;
    font-size: 20px;
  }
  .hero-social p {
    display: none;
  }
  .hero-slider .hero_arrows {
    padding-left: 60px;
    left: 20%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 20px;
  }
  .hero-slide-item .hero-text {
    padding-top: 0;
  }
  .hero-slide-item .hero-text {
    padding-left: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-area .hero-slide-item {
    height: 600px;
  }
  .hero-slide-item .hero-text {
    padding-top: 0;
  }
  .hero-text h2 {
    font-size: var(--heroFont);
    letter-spacing: 2px;
  }
  .section-title {
    font-size: 45px;
    line-height: 54px;
  }
}
@media (max-width: 475px) {
  .hero-slide-item .hero-text {
    padding-left: 0;
  }
  .hero-slide-item .hero-text {
    padding-top: 0;
  }
  .hero-area .hero-social {
    display: none;
  }
  .hero-slider .hero_arrows {
    display: none;
  }
  .hero-slide-item .hero-text {
    /* padding-top: 300px; */
  }
  .hero-text h2 {
    letter-spacing: 1px;
    font-weight: 600;
  }
}
@media (max-width: 395px) {
  .hero-area .hero-slide-item {
    min-height: 110vh;
  }
}

@media (width: 1024px) {
  .hero-slider .owl-nav {
    padding-left: 15%;
  }
  .section-title {
    font-size: 50px;
    letter-spacing: 2px;
    line-height: 55px;
  }
}
.videoCOntainer {
  z-index: 100;
}
