/* ===================== PAGE TITLE ======================*/
.breadcrumb-area {
  padding-top: 300px;
  padding-bottom: 200px;
  text-align: center;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.breadcrumb-area:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #02050b;
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  z-index: -1;
}

.breadcrumb-inn {
  padding-left: 60px;
}

.breadcrumb-inn .main_bradTitle {
  color: #fff;
  font-size: var(--subPageHeroFont);
  letter-spacing: 6px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 65px;
  margin-bottom: 15px;
}

.breadcrumb-inn ul li {
  list-style: none;
  position: relative;
  display: inline-block;
  color: #fff;
  padding-right: 45px;
  margin-right: 10px;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  letter-spacing: 3px;
  font-size: 20px;
}

.breadcrumb-inn ul li:last-child {
  padding: 0px;
  margin: 0px;
}

.breadcrumb-inn ul li:before {
  position: absolute;
  content: "//";
  top: 0px;
  right: 0px;
}

.breadcrumb-inn ul li:last-child:before {
  display: none;
}

.breadcrumb-inn ul li a {
  color: #fff;
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 33px;
  border: 1px solid var(--primaryColor);
  font-size: 16px;
}
.Hero_mobile_Socials {
  display: none;
}
/*Responsive*/
@media (max-width: 991px) {
  .breadcrumb-area .hero-social p {
    display: none;
  }
}

@media (max-width: 767px) {
  .hero-social {
    display: none;
    /* left: -100px; */
    transition: 0.5s;
  }
  .Hero_mobile_Socials {
    display: block;
  }
  .Hero_mobile_Socials {
    padding: 20px 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #222;
  }
  .SocialIconsk3i1 {
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-direction: row;
    font-size: 25px;
    padding: 0 15px;
  }
  /* .breadcrumb-area:hover .hero-social {
    left: 0;
    transition: 0.5s;
  } */
  .breadcrumb-inn ul li {
    font-size: 16px;
  }

  .breadcrumb-inn .main_bradTitle {
    font-size: 25px;
    letter-spacing: 4px;
    line-height: 55px;
  }
  .breadcrumb-area {
    padding-top: 200px;
    padding-bottom: 150px;
  }
  .breadcrumb-inn {
    padding-left: 0;
  }
}
@media (width: 1024px) {
  .breadcrumb-inn {
    padding-left: 60px;
  }
}
