/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/
/* @import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800;900&display=swap"); */

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap"); */

:root {
  --primaryColor: #c21b1e;
  --primaryLightColor: #e62326;
  --primaryDarkColor: #8d1315;
  --secondaryColor: #ffffff;
  /* fonts sizes / */
  /* hero */
  --heroFont: 40px;
  --subPageHeroFont: 35px;
  /* main  */
  --mainHeading: 36px;
  --subHeading: 24px;
  /* secondary  */
  --secHeading: 32px;
  --subSecHeading: 20px;
  /* body */
  --bodyMainFontSize: 18px;
  --bodySecondaryFontSize: 16px;
}
@media (max-width: 767px) {
  /* fonts sizes / */
  :root {
    /* hero */
    --heroFont: 30px;
    --subPageHeroFont: 26px;
    /* main */
    --mainHeading: 30px;
    --subHeading: 22px;
    /* secondary  */
    --secHeading: 28px;
    --subSecHeading: 18px;
    /* body */
    --bodyMainFontSize: 16px;
    --bodySecondaryFontSize: 16px;
  }
}
svg {
  flex-shrink: 0;
}
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #777;
  font-display: swap;
  scroll-behavior: smooth;
}
.body_wrap {
  overflow: hidden;
  position: relative;
}
.img {
  max-width: 100%;
  transition: all 0.3s ease-out 0s;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
}
.change_toUperCase {
  text-transform: uppercase;
}
a,
.button {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a {
  text-decoration: none;
}

a:focus,
a:hover,
.gallery-cat a:hover,
.footer-menu li a:hover {
  text-decoration: none;
}

a,
button {
  color: #000;
  outline: medium none;
  cursor: pointer;
}

a:hover {
  color: var(--primaryColor);
}

button {
  padding: 0px;
  border: none;
  outline: none;
  background: none;
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: #000000;
  margin: 0;
  font-style: normal;
  font-weight: 600;
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  font-size: var(--bodySecondaryFontSize);
  font-weight: normal;
  line-height: 25px;
  color: #777;
  margin-bottom: 15px;
}

p:last-child {
  margin: 0;
}

label {
  color: #7e7e7e;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

input,
textarea,
select {
  width: 100%;
  height: 55px;
  background-color: #f8f8f8;
  border: none;
  padding: 0px 20px;
  outline: none;
}

*::-moz-selection {
  background: var(--primaryLightColor);
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: var(--primaryLightColor);
  color: #fff;
  text-shadow: none;
}

::selection {
  background: var(--primaryLightColor);
  color: #fff;
  text-shadow: none;
}

*::-moz-placeholder {
  color: #999;
  font-size: 16px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

*::-webkit-input-placeholder {
  color: #999;
  font-size: 16px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

*:-ms-input-placeholder {
  color: #999;
  font-size: 16px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

*::placeholder {
  color: #999;
  font-size: 16px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
/*--------------------------------------------------------------
#0.2    Global Elements
--------------------------------------------------------------*/

section {
  padding: 100px 0;
}

.cta-btn {
  display: inline-block;
  padding: 15px 35px;
  font-weight: 500;
  text-transform: capitalize;
  border: 1px solid transparent;
}

.btn-fill {
  background: var(--primaryColor) none repeat scroll 0 0;
  color: #fff;
  padding: 17px 35px;
  border-color: var(--primaryColor);
  transition: 0.3s;
}
.btn-fill:hover {
  background: transparent;
  outline: 1px solid var(--primaryColor);
  transition: 0.3s;
}

.btn-border {
  border-color: #000;
  color: #000;
}

.site-heading {
  margin-bottom: 10px;
}
.rph-btn-primary {
  background-color: var(--primaryColor);
  color: #fff;
  padding: 15px 25px;
  transition: 0.6s;
  font-weight: 500;
}
.rph-btn-primary:hover {
  background-color: transparent;
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  transition: 0.6s;
}
.sub-title {
  color: var(--primaryColor);
  letter-spacing: 2px;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: var(--subHeading);
}
.section-title {
  color: #000;
  font-size: var(--mainHeading);
  letter-spacing: 2px;
  font-weight: 600;
  line-height: 60px;
}

.form-button {
  display: inline-block;
  background: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  padding: 17px 35px;
  color: #fff;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.form-button:hover {
  background: transparent;
  color: var(--primaryColor);
}

/* scroll bar  */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(162, 162, 162);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primaryDarkColor);
}
/* scroll bar end */
/*Responsive*/
@media (max-width: 767px) {
  .section-title {
    font-size: var(--mainHeading);
    line-height: 44px;
  }
  .sub-title {
    letter-spacing: 1px;
    font-size: 20px;
  }
  p {
    font-size: var(--bodySecondaryFontSize);
    line-height: 26px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-title {
    font-size: var(--mainHeading);
    line-height: 54px;
  }
  .sub-title {
    letter-spacing: 2px;
    font-size: 20px;
  }
  p {
    font-size: var(--bodySecondaryFontSize);
    line-height: 26px;
  }
  .counter-box p {
    letter-spacing: 1px;
  }
}
@media (max-width: 550px) {
  section {
    padding: 50px 0;
  }
}

@media (width: 1024px) {
  .section-title {
    font-size: var(--mainHeading);
    letter-spacing: 2px;
    line-height: 55px;
  }
}
